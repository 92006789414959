<template>
  <div
    id="edit-subtask-material-modal"
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 555px">
      <form @submit.prevent.stop="onSubmitForm" class="modal-content">
        <div v-if="state.isDataLoading" class="modal-loading-wrapper">
          <div
            class="spinner-border text-light spinner-border-md ms-2"
            role="status"
          >
            <span class="visually-hidden">Загрузка...</span>
          </div>
        </div>

        <div class="modal-header flex-column">
          <div class="d-flex align-item-center justify-conyent-between w-100">
            <h5 class="main-form-control__title" id="staticBackdropLabel">
              Редактировать подзадачу
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              :disabled="state.isFormLoading"
            ></button>
          </div>

          <div class="main-small-alert mt-2">
            <inline-svg
              class="main-small-alert__icon"
              :src="require('@/assets/images/alert.svg')"
              fill="#717C99"
            />

            <p class="main-small-alert__text">
              Пункты помеченные символом <span class="text-danger">*</span>,
              обязательны для проверки/заполнения.
            </p>
          </div>
        </div>

        <div
          class="modal-body order-tables__modal-form-wrapper scrollbar-light"
          :style="{ overflow: isSelectOpen ? 'hidden' : 'auto' }"
        >
          <div>
            <input class="visually-hidden" type="text" />

            <h3 class="text-bold text-center mb-2">Сырье</h3>
            <div
              class="order-tables__modal-form-inner"
              v-for="(material, index) in state.materials"
              :key="material.id"
            >
              <div class="main-form-control mb-3">
                <label class="main-form-control__label">
                  Наименование сырья: <span class="text-danger">*</span>
                </label>

                <v-select
                  class="main-form-control__select material-select"
                  :class="{
                    error:
                      v$.$errors.length &&
                      v$.materials.$each.$response.$errors[index].id.length,
                  }"
                  placeholder="Наименование"
                  v-model="material.id"
                  :options="state.materialOptions"
                  label="title"
                  :reduce="(item) => item.id"
                  :clearable="false"
                  append-to-body
                  :calculate-position="withPopper"
                >
                  {{ option.title }}
                </v-select>

                <span
                  class="error-warning"
                  v-if="
                    v$.$errors.length &&
                    v$.materials.$each.$response.$errors[index].id.length
                  "
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="main-form-control mb-3">
                <label class="main-form-control__label">
                  Единица измерения: <span class="text-danger">*</span>
                </label>

                <v-select
                  class="main-form-control__select material-select"
                  :class="{
                    error:
                      v$.$errors.length &&
                      v$.materials.$each.$response.$errors[index].measurement
                        .length,
                  }"
                  placeholder="Единица измерения"
                  v-model="material.measurement"
                  :options="measurementOptionsList"
                  label="title"
                  :reduce="(item) => item"
                  :clearable="false"
                  append-to-body
                  :calculate-position="withPopper"
                >
                  {{ option.title }}
                </v-select>

                <span
                  class="error-warning"
                  v-if="
                    v$.$errors.length &&
                    v$.materials.$each.$response.$errors[index].measurement
                      .length
                  "
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="mb-3">
                <label class="main-form-control__label">
                  Кол-во сырья: <span class="text-danger">*</span>
                </label>

                <div class="d-flex">
                  <input
                    class="main-form-control__input"
                    :class="{
                      error:
                        v$.$errors.length &&
                        v$.materials.$each.$response.$errors[index].quantity
                          .length,
                    }"
                    type="text"
                    placeholder="Количество"
                    v-model="material.quantity"
                  />
                  <button
                    v-if="state.materials.length == index + 1"
                    @click="addMaterial"
                    class="btn btn-outline btn-outline-green text-nowrap ms-2"
                    type="button"
                  >
                    Добавить сырье
                  </button>
                </div>

                <span
                  class="error-warning"
                  v-if="
                    v$.$errors.length &&
                    v$.materials.$each.$response.$errors[index].quantity.length
                  "
                  >* Данное поле обязательно</span
                >
              </div>
            </div>

            <h3 class="text-bold text-center mb-2 mt-4">
              Производственные продукты
            </h3>
            <div
              v-for="(product, index) in state.products"
              :key="product.id"
              class="order-tables__modal-form-inner"
            >
              <div class="main-form-control mb-3">
                <label class="main-form-control__label">
                  Наименование продукта: <span class="text-danger">*</span>
                </label>

                <v-select
                  class="main-form-control__select material-select"
                  :class="{
                    error:
                      v$.$errors.length &&
                      v$.products.$each.$response.$errors[index].id.length,
                  }"
                  placeholder="Наименование"
                  v-model="product.id"
                  :options="productOptionsList"
                  label="title"
                  :reduce="(item) => item.id"
                  :clearable="false"
                  append-to-body
                  :calculate-position="withPopper"
                >
                  {{ option.title }}
                </v-select>

                <span
                  class="error-warning"
                  v-if="
                    v$.$errors.length &&
                    v$.products.$each.$response.$errors[index].id.length
                  "
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="main-form-control mb-3">
                <label class="main-form-control__label">
                  Единица измерения: <span class="text-danger">*</span>
                </label>

                <v-select
                  class="main-form-control__select material-select"
                  :class="{
                    error:
                      v$.$errors.length &&
                      v$.products.$each.$response.$errors[index].measurement
                        .length,
                  }"
                  placeholder="Единица измерения"
                  v-model="product.measurement"
                  :options="measurementOptionsList"
                  label="title"
                  :reduce="(item) => item"
                  :clearable="false"
                  append-to-body
                  :calculate-position="withPopper"
                >
                  {{ option.title }}
                </v-select>

                <span
                  class="error-warning"
                  v-if="
                    v$.$errors.length &&
                    v$.products.$each.$response.$errors[index].measurement
                      .length
                  "
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="main-form-control mb-3">
                <label class="main-form-control__label">
                  Склад: <span class="text-danger">*</span>
                </label>

                <v-select
                  class="main-form-control__select material-select"
                  :class="{
                    error:
                      v$.$errors.length &&
                      v$.products.$each.$response.$errors[index].stock_id
                        .length,
                  }"
                  placeholder="Склад"
                  v-model="product.stock_id"
                  :options="stockOptionsList"
                  label="title"
                  :reduce="(item) => item.stock_id_c"
                  :clearable="false"
                  append-to-body
                  :calculate-position="withPopper"
                >
                  {{ option.title }}
                </v-select>

                <span
                  class="error-warning"
                  v-if="
                    v$.$errors.length &&
                    v$.products.$each.$response.$errors[index].stock_id.length
                  "
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="mb-3">
                <label class="main-form-control__label">
                  Кол-во продукта: <span class="text-danger">*</span>
                </label>

                <div class="d-flex">
                  <input
                    class="main-form-control__input"
                    :class="{
                      error:
                        v$.$errors.length &&
                        v$.products.$each.$response.$errors[index].quantity
                          .length,
                    }"
                    type="text"
                    placeholder="Количество"
                    v-model="product.quantity"
                  />
                  <button
                    v-if="state.products.length == index + 1"
                    @click="addProduct"
                    class="btn btn-outline btn-outline-green text-nowrap ms-2"
                    type="button"
                  >
                    Добавить продукт
                  </button>
                </div>

                <span
                  class="error-warning"
                  v-if="
                    v$.$errors.length &&
                    v$.products.$each.$response.$errors[index].quantity.length
                  "
                  >* Данное поле обязательно</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="d-flex w-100">
            <button
              type="button"
              class="btn btn-outline-white btn-md w-50 me-3"
              :disabled="state.isFormLoading"
              @click="onResetForm"
            >
              Отмена
            </button>
            <button
              type="submit"
              class="btn btn-gold btn-md w-50 ms-3"
              :disabled="state.isFormLoading"
              @click.stop
            >
              <span>Сохранить</span>

              <div
                v-if="state.isFormLoading"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { toRef, reactive, computed, watch } from "vue";

import { required, helpers, numeric } from "@vuelidate/validators";
import { errorAlert } from "@/helpers/alerts";
import useModal from "@/mixins/useModal";
import popperSelect from "@/mixins/popperSelect";

import { getMaterials } from "@/api/material";

export default {
  mixins: [popperSelect],
  props: [
    "productData",
    "productOptionsList",
    "stockOptionsList",
    "measurementOptionsList",
    "apiRequest",
    "refetch",
  ],
  emits: ["update:productData"],
  setup(props, { emit }) {
    const productData = toRef(props, "productData");
    const state = reactive({
      isFormLoading: false,
      isDataLoading: false,
      materials: [
        {
          id: null,
          measurement: null,
          quantity: null,
        },
      ],
      products: [
        {
          id: null,
          measurement: null,
          stock_id: null,
          quantity: null,
        },
      ],
      materialOptions: [],
    });

    const rules = computed(() => ({
      materials: {
        $each: helpers.forEach({
          id: { required },
          measurement: { required },
          quantity: { required, numeric, minValue: (item) => item >= 1 },
        }),
      },
      products: {
        $each: helpers.forEach({
          id: {},
          measurement: {},
          stock_id: {},
          quantity: {},
        }),
      },
    }));

    const { modalRef, v$, onResetForm, successSaveAlert, hideModal } = useModal(
      props,
      {
        state,
        rules,
      }
    );

    watch(productData, async () => {
      if (productData.value == null) return;

      try {
        state.isDataLoading = true;
        const { data } = await getMaterials(productData.value.id);
        state.materialOptions = data.materials;
        if (data.productMaterials.length)
          state.materials = data.productMaterials;
        if (data.derivativeProducts.length)
          state.products = data.derivativeProducts;
      } catch {
        errorAlert();
      } finally {
        state.isDataLoading = false;
      }
    });

    function addMaterial() {
      state.materials.push({
        id: null,
        measurement: null,
        quantity: null,
      });
    }

    function addProduct() {
      state.products.push({
        id: null,
        measurement: null,
        stock_id: null,
        quantity: null,
      });
    }

    function resetData() {
      emit("update:productData", null);
      state.materialOptions = [];
      state.materials = [
        {
          id: null,
          measurement: null,
          quantity: null,
        },
      ];
    }

    async function onSubmitForm() {
      await this.v$.materials.$touch();
      await this.v$.products.$touch();
      if (this.v$.materials.$invalid || this.v$.products.$invalid) return;
      // if (this.v$.materials.$invalid) return;

      try {
        state.isFormLoading = true;
        await props.apiRequest(
          productData.value.dealId,
          productData.value.taskId,
          productData.value.subtaskId,
          {
            materials: state.materials,
            derivative_products: state.products ?? [],
          }
        );

        successSaveAlert().then(() => {
          hideModal(modalRef.value);
          resetData();
          props.refetch();
        });
      } catch (error) {
        if (error?.response?.status == 406)
          errorAlert("Не достаточно материалов на складе");
        else errorAlert();
      } finally {
        state.isFormLoading = false;
      }
    }

    return {
      modalRef,
      state,
      v$,
      onSubmitForm,
      onResetForm,
      addMaterial,
      addProduct,
    };
  },
};
</script>

<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

.vs__dropdown-menu {
  z-index: 100000 !important;
}

.material-select .vs__selected {
  width: 410px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 37.5px;
}
</style>