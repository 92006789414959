<template>
  <div class="order-tables mb-5">
    <div class="order-tables__wrapper">
      <form
        @submit.prevent="onSubmitForm"
        class="order-tables__item order-tables__item--gold"
      >
        <div class="order-tables__item-top">
          <!-- <div class="order-tables__item-top-actions">
            <button
              type="button"
              class="order-tables__item-top-actions-btn"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <inline-svg
                :src="require('@/assets/images/dots.svg')"
                fill="#000"
              />
            </button>

            <ul class="dropdown-menu dropdown-menu-end dropdown-dark">
              <li>
                <button type="button" class="dropdown-item">
                  Сохранить в черновик
                </button>
              </li>
            </ul>
          </div> -->

          <div v-if="state.deal?.bitrix_id !== 101" class="d-flex flex-wrap">
            <div class="d-flex flex-column me-4">
              <h4 class="order-tables__item-top-title">
                {{ state.deal?.bitrix_id }}
              </h4>
              <span class="order-tables__item-top-subtitle">Номер заявки</span>
            </div>

            <div class="d-flex flex-column">
              <h4 class="order-tables__item-top-title">
                {{ state.deal?.title }}
              </h4>
              <span class="order-tables__item-top-subtitle"
                >Название заявки</span
              >
            </div>
          </div>
          <div v-else class="d-flex flex-wrap">
            <div class="d-flex flex-column">
              <h4 class="order-tables__item-top-title">
                Задачи без привязки к заявке
              </h4>
            </div>
          </div>
          <div
            v-if="v$.deal.tasks.$errors?.length"
            class="order-tables__item-top-errors mt-2"
          >
            <div class="order-tables__item-top-error danger">
              <inline-svg
                :src="require('@/assets/images/error.svg')"
                stroke="#FF0558"
              />
              <span>Ошибка ввода данных</span>
            </div>
          </div>
        </div>

        <div class="scrollbar-light" style="overflow-y: hidden">
          <div class="grid-container">
            <div class="grid-header text-center">№</div>
            <div class="grid-header">Продукт</div>
            <div class="grid-header">Кол-во</div>
            <div class="grid-header">Цех</div>
            <div class="grid-header">Участок</div>
            <div class="grid-header">Исполнитель</div>
            <div class="grid-header">Время</div>
            <div class="grid-header">Сырье</div>
            <div class="grid-header">Подробнее</div>
            <div class="grid-header"></div>

            <template v-for="(item, index) in state.deal?.tasks" :key="item.id">
              <div class="maintask">
                <div class="grid-item text-center">
                  {{ index + 1 }}
                </div>
                <div class="grid-item">
                  <v-select
                    class="order-tables__select"
                    :class="{
                      error:
                        v$.deal.tasks.$errors?.length &&
                        v$.deal.tasks?.$each?.$response?.$errors[index]?.product
                          ?.length,
                    }"
                    label="title"
                    :title="item.product?.title"
                    :options="state.productOptionsList"
                    :reduce="(option) => option"
                    :clearable="false"
                    disabled
                    :calculate-position="withPopper"
                    append-to-body
                    v-model="item.product"
                    @update:modelValue="
                      updateData({
                        taskId: item.id,
                        subtaskId: null,
                        isNotLoading: false,
                        order: index,
                      })
                    "
                  >
                    <template v-slot:no-options>Ничего не найдено.</template>
                  </v-select>
                </div>
                <div class="grid-item">
                  <input
                    type="text"
                    class="order-tables__input w-100"
                    :class="{
                      error:
                        v$.deal.tasks.$errors?.length &&
                        v$.deal.tasks?.$each?.$response?.$errors[index]
                          ?.quantity?.length,
                    }"
                    style="min-width: 90px"
                    v-model="item.quantity"
                    :title="item.quantity"
                    disabled
                    @change="
                      updateData({
                        taskId: item.id,
                        subtaskId: null,
                        isNotLoading: false,
                        order: index,
                      })
                    "
                  />
                </div>
                <div class="grid-item">
                  <v-select
                    class="order-tables__select"
                    :class="{
                      error:
                        v$.deal.tasks.$errors?.length &&
                        v$.deal.tasks?.$each?.$response?.$errors[index]
                          ?.manufacture?.length,
                    }"
                    label="title"
                    :title="item.manufacture?.title"
                    :options="state.manufactureOptionsList"
                    :reduce="(option) => option"
                    :clearable="false"
                    :disabled="
                      state.deal?.status_id != 1 ||
                      state.deal?.is_disabled == true
                    "
                    :calculate-position="withPopper"
                    append-to-body
                    v-model="item.manufacture"
                    @update:modelValue="
                      item.area = null;
                      item.users = null;
                      item.manufactureUserList = [];
                      item.manufactureAreaList = [];
                      updateData({
                        taskId: item.id,
                        subtaskId: null,
                        isNotLoading: false,
                        order: index,
                      });
                    "
                  >
                    <template v-slot:no-options>Ничего не найдено.</template>
                  </v-select>
                </div>
                <div class="grid-item">
                  <v-select
                    class="order-tables__select"
                    :class="{
                      error:
                        v$.deal.tasks.$errors?.length &&
                        v$.deal.tasks?.$each?.$response?.$errors[index]?.area
                          ?.length,
                    }"
                    label="title"
                    :title="item.area?.title"
                    :options="item.manufactureAreaList"
                    :reduce="(option) => option"
                    :clearable="false"
                    :disabled="
                      state.deal?.status_id != 1 ||
                      state.deal?.is_disabled == true
                    "
                    :calculate-position="withPopper"
                    append-to-body
                    v-model="item.area"
                    :loading="item.isManufactureAreaListLoading"
                    @open="
                      getManufactureAreaList({
                        taskId: item.id,
                        shopId: item.manufacture?.id,
                      })
                    "
                    @update:modelValue="
                      updateData({
                        taskId: item.id,
                        subtaskId: null,
                        isNotLoading: false,
                        order: index,
                      })
                    "
                  >
                    <template v-slot:no-options>Ничего не найдено.</template>
                  </v-select>
                </div>
                <div class="grid-item">
                  <v-select
                    class="order-tables__select"
                    :class="{
                      error:
                        v$.deal.tasks.$errors?.length &&
                        v$.deal.tasks?.$each?.$response?.$errors[index]?.users
                          ?.length,
                    }"
                    label="name"
                    :options="item.manufactureUserList"
                    :reduce="(option) => option"
                    :clearable="false"
                    :loading="item.isManufactureUserListLoading"
                    :disabled="
                      !item.manufacture ||
                      state.deal?.status_id != 1 ||
                      state.deal?.is_disabled == true
                    "
                    :calculate-position="withPopper"
                    append-to-body
                    multiple
                    v-model="item.users"
                    @open="
                      getManufactureUserList({
                        taskId: item.id,
                        manufactureId: item.manufacture?.id,
                        receiverManufactureId: item.receiver_manufacture?.id,
                      })
                    "
                    @update:modelValue="
                      updateData({
                        taskId: item.id,
                        subtaskId: null,
                        isNotLoading: false,
                        order: index,
                      })
                    "
                  >
                    <template v-slot:no-options>Ничего не найдено.</template>
                  </v-select>

                  <span
                    v-if="
                      v$.deal.tasks.$errors?.length &&
                      v$.deal.tasks?.$each?.$response?.$errors[index]?.users
                        ?.length &&
                      item.manufacture == null
                    "
                    class="error-warning m-0"
                    style="max-width: 160px"
                    >* Необходимо добавить цех</span
                  >
                </div>
                <div class="grid-item">
                  <VueDatePicker
                    class="order-tables__datepicker"
                    :class="{
                      error:
                        v$.deal.tasks.$errors?.length &&
                        v$.deal.tasks?.$each?.$response?.$errors[index]
                          ?.need_time?.length,
                    }"
                    :clearable="false"
                    :disabled="
                      state.deal?.status_id != 1 ||
                      state.deal?.is_disabled == true ||
                      item.subtasks?.length > 0
                    "
                    :format="'ss:HH:mm'"
                    v-model="item.need_time"
                    time-picker
                    enable-seconds
                    @update:modelValue="
                      updateData({
                        taskId: item.id,
                        subtaskId: null,
                        isNotLoading: false,
                        order: index,
                      })
                    "
                  ></VueDatePicker>
                </div>
                <div class="grid-item">
                  <div class="d-flex justify-content-center">
                    <button
                      class="order-tables__edit-material-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-material-modal"
                      @click="
                        productToUpdate = {
                          id: item.product?.id,
                          dealId: dealId,
                          taskId: item.id,
                        }
                      "
                      :disabled="
                        state.deal?.status_id != 1 ||
                        state.deal?.is_disabled == true ||
                        !item.product
                      "
                    >
                      <inline-svg
                        :src="require('@/assets/images/edit-1.svg')"
                        fill="#96AEC1"
                        opacity="0.65"
                      />
                    </button>
                  </div>
                </div>
                <div class="grid-item">
                  <div class="d-flex justify-content-center">
                    <button
                      type="button"
                      class="order-tables__edit-material-btn"
                      :class="{
                        error:
                          v$.deal.tasks.$errors?.length &&
                          v$.deal.tasks?.$each?.$response?.$errors[index]?.stock
                            ?.length,
                      }"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-manufacture-modal"
                      title="Подробнее"
                      @click="
                        productManufactureToUpdate = {
                          id: item.id,
                          deal_id: item.deal_id,
                          isDisabled:
                            state.deal?.status_id != 1 ||
                            state.deal?.is_disabled == true,
                          product: item.product,
                          quantity: item.quantity,
                          manufacture: item.manufacture,
                          area: item.area,
                          recivier_manufacture: item.receiver_manufacture,
                          recivier_area: item.receiver_area,
                          stock: item.stock,
                          users: item.users,
                          need_time: item.need_time,
                          is_product: true,
                          order: index,
                          is_datepicker_disabled: item.subtasks?.length > 0,
                          measurement: item.measurement,
                        }
                      "
                    >
                      <inline-svg
                        width="20"
                        height="20"
                        :fill="
                          v$.deal.tasks.$errors?.length &&
                          v$.deal.tasks?.$each?.$response?.$errors[index]?.stock
                            ?.length
                            ? '#ff0558'
                            : '#96aec1'
                        "
                        :src="require('@/assets/images/dots.svg')"
                      />
                    </button>
                  </div>

                  <span
                    v-if="
                      v$.deal.tasks.$errors?.length &&
                      v$.deal.tasks?.$each?.$response?.$errors[index]?.stock
                        ?.length
                    "
                    class="error-warning m-0"
                  >
                    * Необходимо указать склад нажав на кнопку '...'
                  </span>
                </div>
                <div class="grid-item">
                  <button
                    type="button"
                    class="order-tables__action-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#add-manufacture-modal"
                    title="Добавить подзадачу"
                    :disabled="
                      state.deal?.status_id != 1 ||
                      state.deal?.is_disabled == true
                    "
                    @click="
                      productManufactureToAdd = {
                        id: item.id,
                        deal_id: item.deal_id,
                        isDisabled:
                          state.deal?.status_id != 1 ||
                          state.deal?.is_disabled == true,
                        task_id: item.id,
                        task_order: index,
                      }
                    "
                  >
                    <inline-svg
                      width="24"
                      height="24"
                      stroke="#96aec1"
                      :src="require('@/assets/images/plus.svg')"
                    />
                  </button>
                </div>
              </div>

              <draggable
                :list="item.subtasks"
                :group="{ name: 'g1' }"
                item-key="id"
                tag="div"
                ghostClass="ghost-ticket"
                :disabled="
                  state.deal?.status_id != 1 ||
                  state.deal?.is_disabled == true ||
                  state.deal?.is_disabled == true
                "
                @change="(event) => onDragEnd(event, item.id)"
                class="grid-item-wrapper"
              >
                <template #item="{ element: subtask, index: subtaskIndex }">
                  <div
                    class="subtask"
                    :class="{
                      'not-draggable':
                        state.deal?.status_id != 1 ||
                        state.deal?.is_disabled == true ||
                        state.deal?.is_disabled == true,
                    }"
                  >
                    <div class="grid-item p-0">
                      <div class="dots-vertical-wrapper">
                        <div class="dots-vertical">
                          <inline-svg
                            :src="require('@/assets/images/dots-vertical.svg')"
                          />
                        </div>

                        {{ index + 1 }}.{{ subtaskIndex + 1 }}
                      </div>
                    </div>

                    <div class="grid-item">
                      <v-select
                        class="order-tables__select"
                        :class="{
                          error:
                            v$.deal.tasks.$errors?.length &&
                            v$.deal.tasks.$each.$response.$data[index].subtasks
                              .$each.$errors[subtaskIndex]?.product.length,
                        }"
                        label="title"
                        :title="subtask.product?.title"
                        :options="state.productOptionsList"
                        :reduce="(option) => option"
                        :clearable="false"
                        :disabled="
                          state.deal?.status_id != 1 ||
                          state.deal?.is_disabled == true
                        "
                        :calculate-position="withPopper"
                        append-to-body
                        v-model="subtask.product"
                        @update:modelValue="
                          updateData({
                            taskId: item.id,
                            subtaskId: subtask.id,
                            isNotLoading: false,
                            order: subtaskIndex,
                          })
                        "
                      >
                        <template v-slot:no-options
                          >Ничего не найдено.</template
                        >
                      </v-select>
                    </div>
                    <div class="grid-item">
                      <input
                        type="text"
                        class="order-tables__input w-100"
                        :class="{
                          error:
                            v$.deal.tasks.$errors?.length &&
                            v$.deal.tasks.$each.$response.$data[index].subtasks
                              .$each.$errors[subtaskIndex]?.quantity.length,
                        }"
                        style="min-width: 90px"
                        v-model="subtask.quantity"
                        :title="subtask.quantity"
                        :disabled="
                          state.deal?.status_id != 1 ||
                          state.deal?.is_disabled == true
                        "
                        @change="
                          updateData({
                            taskId: item.id,
                            subtaskId: subtask.id,
                            isNotLoading: false,
                            order: subtaskIndex,
                          })
                        "
                      />
                    </div>
                    <div class="grid-item">
                      <v-select
                        class="order-tables__select"
                        :class="{
                          error:
                            v$.deal.tasks.$errors?.length &&
                            v$.deal.tasks.$each.$response.$data[index].subtasks
                              .$each.$errors[subtaskIndex]?.manufacture.length,
                        }"
                        label="title"
                        :title="item.manufacture?.title"
                        :options="state.manufactureOptionsList"
                        :reduce="(option) => option"
                        :clearable="false"
                        :disabled="
                          state.deal?.status_id != 1 ||
                          state.deal?.is_disabled == true
                        "
                        :calculate-position="withPopper"
                        append-to-body
                        v-model="subtask.manufacture"
                        @update:modelValue="
                          subtask.area = null;
                          subtask.users = null;
                          subtask.manufactureAreaList = [];
                          subtask.manufactureUserList = [];
                          updateData({
                            taskId: item.id,
                            subtaskId: subtask.id,
                            isNotLoading: false,
                            order: subtaskIndex,
                          });
                        "
                      >
                        <template v-slot:no-options
                          >Ничего не найдено.</template
                        >
                      </v-select>
                    </div>
                    <div class="grid-item">
                      <v-select
                        class="order-tables__select"
                        :class="{
                          error:
                            v$.deal.tasks.$errors?.length &&
                            v$.deal.tasks.$each.$response.$data[index].subtasks
                              .$each.$errors[subtaskIndex]?.area.length,
                        }"
                        label="title"
                        :title="item.area?.title"
                        :options="subtask.manufactureAreaList"
                        :reduce="(option) => option"
                        :clearable="false"
                        :disabled="
                          state.deal?.status_id != 1 ||
                          state.deal?.is_disabled == true
                        "
                        :calculate-position="withPopper"
                        append-to-body
                        v-model="subtask.area"
                        :loading="subtask.isManufactureAreaListLoading"
                        @open="
                          getManufactureAreaList({
                            taskId: item.id,
                            subtaskId: subtask.id,
                            shopId: subtask.manufacture?.id,
                          })
                        "
                        @update:modelValue="
                          updateData({
                            taskId: item.id,
                            subtaskId: subtask.id,
                            isNotLoading: false,
                            order: subtaskIndex,
                          })
                        "
                      >
                        <template v-slot:no-options
                          >Ничего не найдено.</template
                        >
                      </v-select>
                    </div>
                    <div class="grid-item">
                      <v-select
                        class="order-tables__select"
                        :class="{
                          error:
                            v$.deal.tasks.$errors?.length &&
                            v$.deal.tasks.$each.$response.$data[index].subtasks
                              .$each.$errors[subtaskIndex]?.users.length,
                        }"
                        label="name"
                        :options="subtask.manufactureUserList"
                        :reduce="(option) => option"
                        :clearable="false"
                        :loading="subtask.isManufactureUserListLoading"
                        :disabled="
                          !subtask.manufacture ||
                          state.deal?.status_id != 1 ||
                          state.deal?.is_disabled == true
                        "
                        :calculate-position="withPopper"
                        append-to-body
                        multiple
                        v-model="subtask.users"
                        @open="
                          getManufactureUserList({
                            taskId: item.id,
                            subtaskId: subtask.id,
                            manufactureId: subtask.manufacture?.id,
                            receiverManufactureId:
                              subtask.receiver_manufacture?.id,
                          })
                        "
                        @update:modelValue="
                          updateData({
                            taskId: item.id,
                            subtaskId: subtask.id,
                            isNotLoading: false,
                            order: subtaskIndex,
                          })
                        "
                      >
                        <template v-slot:no-options
                          >Ничего не найдено.</template
                        >
                      </v-select>

                      <span
                        v-if="
                          v$.deal.tasks.$errors?.length &&
                          v$.deal.tasks.$each.$response.$data[index].subtasks
                            .$each.$errors[subtaskIndex]?.users.length &&
                          subtask.manufacture == null
                        "
                        class="error-warning m-0"
                        style="max-width: 160px"
                        >* Необходимо добавить цех</span
                      >
                    </div>
                    <div class="grid-item">
                      <VueDatePicker
                        class="order-tables__datepicker"
                        :class="{
                          error:
                            v$.deal.tasks.$errors?.length &&
                            v$.deal.tasks.$each.$response.$data[index].subtasks
                              .$each.$errors[subtaskIndex]?.need_time.length,
                        }"
                        :clearable="false"
                        :disabled="
                          state.deal?.status_id != 1 ||
                          state.deal?.is_disabled == true ||
                          subtask?.subtasks?.length > 0
                        "
                        :format="'ss:HH:mm'"
                        v-model="subtask.need_time"
                        time-picker
                        enable-seconds
                        @update:modelValue="
                          updateTaskTime({
                            taskId: item.id,
                            subtaskId: subtask.id,
                            taskOrder: index,
                            subtaskOrder: subtaskIndex,
                          })
                        "
                      ></VueDatePicker>
                    </div>
                    <div class="grid-item">
                      <div class="d-flex justify-content-center">
                        <button
                          class="order-tables__edit-material-btn"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-subtask-material-modal"
                          @click="
                            productToUpdate = {
                              id: subtask.product?.id,
                              dealId: dealId,
                              taskId: item.id,
                              subtaskId: subtask.id,
                            }
                          "
                          :disabled="
                            state.deal?.status_id != 1 ||
                            state.deal?.is_disabled == true ||
                            !subtask.product
                          "
                        >
                          <inline-svg
                            :src="require('@/assets/images/edit-1.svg')"
                            fill="#96AEC1"
                            opacity="0.65"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="grid-item">
                      <div class="d-flex justify-content-center">
                        <button
                          type="button"
                          class="order-tables__edit-material-btn"
                          :class="{
                            error:
                              v$.deal.tasks.$errors?.length &&
                              v$.deal.tasks.$each.$response.$data[index]
                                .subtasks.$each.$errors[subtaskIndex]?.stock
                                .length,
                          }"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-manufacture-modal"
                          title="Подробнее"
                          @click="
                            productManufactureToUpdate = {
                              id: subtask.id,
                              deal_id: item.deal_id,
                              isDisabled:
                                state.deal?.status_id != 1 ||
                                state.deal?.is_disabled == true,
                              product: subtask.product,
                              quantity: subtask.quantity,
                              manufacture: subtask.manufacture,
                              area: subtask.area,
                              recivier_manufacture:
                                subtask.receiver_manufacture,
                              recivier_area: subtask.receiver_area,
                              stock: subtask.stock,
                              users: subtask.users,
                              need_time: subtask.need_time,
                              is_product: false,
                              order: subtaskIndex,
                              task_id: item.id,
                              task_order: index,
                              subtask_id: subtask?.id,
                              subtask_order: subtaskIndex,
                              is_datepicker_disabled:
                                subtask?.subtasks?.length > 0,
                              measurement: subtask.measurement,
                            }
                          "
                        >
                          <inline-svg
                            width="20"
                            height="20"
                            :fill="
                              v$.deal.tasks.$errors?.length &&
                              v$.deal.tasks.$each.$response.$data[index]
                                .subtasks.$each.$errors[subtaskIndex]?.stock
                                .length
                                ? '#ff0558'
                                : '#96aec1'
                            "
                            :src="require('@/assets/images/dots.svg')"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="grid-item">
                      <button
                        type="button"
                        class="order-tables__action-btn"
                        title="Удалить подзадачу"
                        :disabled="
                          state.deal?.status_id != 1 ||
                          state.deal?.is_disabled == true
                        "
                        @click="
                          removeSubtask(
                            item.deal_id,
                            item.id,
                            subtask.id,
                            index
                          )
                        "
                      >
                        <inline-svg
                          width="24"
                          height="24"
                          stroke="#96aec1"
                          :src="require('@/assets/images/minus.svg')"
                        />
                      </button>
                    </div>

                    <draggable
                      :list="subtask.subtasks"
                      :group="{ name: 'g1' }"
                      item-key="id"
                      tag="div"
                      ghostClass="ghost-ticket"
                      :disabled="
                        state.deal?.status_id != 1 ||
                        state.deal?.is_disabled == true
                      "
                      @change="
                        (event) =>
                          onDragEndSubtask(
                            event,
                            item.deal_id,
                            item.id,
                            subtask.id
                          )
                      "
                      class="grid-subtask-wrapper"
                    >
                      <template
                        #item="{
                          element: parallelSubtask,
                          index: parallelSubtaskIndex,
                        }"
                      >
                        <div
                          class="subtask"
                          :class="{
                            'not-draggable':
                              state.deal?.status_id != 1 ||
                              state.deal?.is_disabled == true,
                          }"
                        >
                          <div class="grid-item p-0">
                            <div class="dots-vertical-wrapper">
                              <div class="dots-vertical">
                                <inline-svg
                                  :src="
                                    require('@/assets/images/dots-vertical.svg')
                                  "
                                />
                              </div>

                              <!-- {{ index + 1 }}.{{ subtaskIndex + 1 }}.{{
                                parallelSubtaskIndex + 1
                              }} -->
                            </div>
                          </div>

                          <div class="grid-item">
                            <v-select
                              class="order-tables__select"
                              :class="{
                                error:
                                  v$.deal.tasks.$errors?.length &&
                                  v$.deal.tasks.$each.$response.$data[index]
                                    .subtasks.$each.$data[subtaskIndex].subtasks
                                    .$each.$errors[parallelSubtaskIndex]
                                    ?.product.length,
                              }"
                              label="title"
                              :title="subtask.product?.title"
                              :options="state.productOptionsList"
                              :reduce="(option) => option"
                              :clearable="false"
                              :disabled="
                                state.deal?.status_id != 1 ||
                                state.deal?.is_disabled == true
                              "
                              :calculate-position="withPopper"
                              append-to-body
                              v-model="parallelSubtask.product"
                              @update:modelValue="
                                updateData({
                                  taskId: item.id,
                                  subtaskId: subtask.id,
                                  parallelSubtaskId: parallelSubtask.id,
                                  isNotLoading: false,
                                  order: parallelSubtaskIndex,
                                })
                              "
                            >
                              <template v-slot:no-options
                                >Ничего не найдено.</template
                              >
                            </v-select>
                          </div>
                          <div class="grid-item">
                            <input
                              type="text"
                              class="order-tables__input w-100"
                              :class="{
                                error:
                                  v$.deal.tasks.$errors?.length &&
                                  v$.deal.tasks.$each.$response.$data[index]
                                    .subtasks.$each.$data[subtaskIndex].subtasks
                                    .$each.$errors[parallelSubtaskIndex]
                                    ?.quantity.length,
                              }"
                              style="min-width: 90px"
                              v-model="parallelSubtask.quantity"
                              :title="parallelSubtask.quantity"
                              :disabled="
                                state.deal?.status_id != 1 ||
                                state.deal?.is_disabled == true
                              "
                              @change="
                                updateData({
                                  taskId: item.id,
                                  subtaskId: subtask.id,
                                  parallelSubtaskId: parallelSubtask.id,
                                  isNotLoading: false,
                                  order: parallelSubtaskIndex,
                                })
                              "
                            />
                          </div>
                          <div class="grid-item">
                            <v-select
                              class="order-tables__select"
                              :class="{
                                error:
                                  v$.deal.tasks.$errors?.length &&
                                  v$.deal.tasks.$each.$response.$data[index]
                                    .subtasks.$each.$data[subtaskIndex].subtasks
                                    .$each.$errors[parallelSubtaskIndex]
                                    ?.manufacture.length,
                              }"
                              label="title"
                              :title="item.manufacture?.title"
                              :options="state.manufactureOptionsList"
                              :reduce="(option) => option"
                              :clearable="false"
                              :disabled="
                                state.deal?.status_id != 1 ||
                                state.deal?.is_disabled == true
                              "
                              :calculate-position="withPopper"
                              append-to-body
                              v-model="parallelSubtask.manufacture"
                              @update:modelValue="
                                parallelSubtask.area = null;
                                parallelSubtask.users = null;
                                parallelSubtask.manufactureAreaList = [];
                                parallelSubtask.manufactureUserList = [];
                                updateData({
                                  taskId: item.id,
                                  subtaskId: subtask.id,
                                  parallelSubtaskId: parallelSubtask.id,
                                  isNotLoading: false,
                                  order: parallelSubtaskIndex,
                                });
                              "
                            >
                              <template v-slot:no-options
                                >Ничего не найдено.</template
                              >
                            </v-select>
                          </div>
                          <div class="grid-item">
                            <v-select
                              class="order-tables__select"
                              :class="{
                                error:
                                  v$.deal.tasks.$errors?.length &&
                                  v$.deal.tasks.$each.$response.$data[index]
                                    .subtasks.$each.$data[subtaskIndex].subtasks
                                    .$each.$errors[parallelSubtaskIndex]?.area
                                    .length,
                              }"
                              label="title"
                              :title="item.area?.title"
                              :options="parallelSubtask.manufactureAreaList"
                              :reduce="(option) => option"
                              :clearable="false"
                              :disabled="
                                state.deal?.status_id != 1 ||
                                state.deal?.is_disabled == true
                              "
                              :calculate-position="withPopper"
                              append-to-body
                              v-model="parallelSubtask.area"
                              :loading="
                                parallelSubtask.isManufactureAreaListLoading
                              "
                              @open="
                                getManufactureAreaList({
                                  taskId: item.id,
                                  subtaskId: subtask.id,
                                  parallelSubtaskId: parallelSubtask.id,
                                  shopId: parallelSubtask.manufacture?.id,
                                })
                              "
                              @update:modelValue="
                                updateData({
                                  taskId: item.id,
                                  subtaskId: subtask.id,
                                  parallelSubtaskId: parallelSubtask.id,
                                  isNotLoading: false,
                                  order: parallelSubtaskIndex,
                                })
                              "
                            >
                              <template v-slot:no-options
                                >Ничего не найдено.</template
                              >
                            </v-select>
                          </div>
                          <div class="grid-item">
                            <v-select
                              class="order-tables__select"
                              :class="{
                                error:
                                  v$.deal.tasks.$errors?.length &&
                                  v$.deal.tasks.$each.$response.$data[index]
                                    .subtasks.$each.$data[subtaskIndex].subtasks
                                    .$each.$errors[parallelSubtaskIndex]?.users
                                    .length,
                              }"
                              label="name"
                              :options="parallelSubtask.manufactureUserList"
                              :reduce="(option) => option"
                              :clearable="false"
                              :loading="
                                parallelSubtask.isManufactureUserListLoading
                              "
                              :disabled="
                                !parallelSubtask.manufacture ||
                                state.deal?.status_id != 1 ||
                                state.deal?.is_disabled == true
                              "
                              :calculate-position="withPopper"
                              append-to-body
                              multiple
                              v-model="parallelSubtask.users"
                              @open="
                                getManufactureUserList({
                                  taskId: item.id,
                                  subtaskId: subtask.id,
                                  parallelSubtaskId: parallelSubtask.id,
                                  manufactureId:
                                    parallelSubtask.manufacture?.id,
                                  receiverManufactureId:
                                    parallelSubtask.receiver_manufacture?.id,
                                })
                              "
                              @update:modelValue="
                                updateData({
                                  taskId: item.id,
                                  subtaskId: subtask.id,
                                  parallelSubtaskId: parallelSubtask.id,
                                  isNotLoading: false,
                                  order: parallelSubtaskIndex,
                                })
                              "
                            >
                              <template v-slot:no-options
                                >Ничего не найдено.</template
                              >
                            </v-select>

                            <span
                              v-if="
                                v$.deal.tasks.$errors?.length &&
                                v$.deal.tasks.$each.$response.$data[index]
                                  .subtasks.$each.$data[subtaskIndex].subtasks
                                  .$each.$errors[parallelSubtaskIndex]?.users
                                  .length &&
                                parallelSubtask.manufacture == null
                              "
                              class="error-warning m-0"
                              style="max-width: 160px"
                              >* Необходимо добавить цех</span
                            >
                          </div>
                          <div class="grid-item">
                            <VueDatePicker
                              class="order-tables__datepicker"
                              :class="{
                                error:
                                  v$.deal.tasks.$errors?.length &&
                                  v$.deal.tasks.$each.$response.$data[index]
                                    .subtasks.$each.$data[subtaskIndex].subtasks
                                    .$each.$errors[parallelSubtaskIndex]
                                    ?.need_time.length,
                              }"
                              :clearable="false"
                              :disabled="
                                state.deal?.status_id != 1 ||
                                state.deal?.is_disabled == true
                              "
                              :format="'ss:HH:mm'"
                              v-model="parallelSubtask.need_time"
                              time-picker
                              enable-seconds
                              @update:modelValue="
                                updateTaskTime({
                                  taskId: item.id,
                                  subtaskId: subtask.id,
                                  parallelSubtaskId: parallelSubtask.id,
                                  taskOrder: index,
                                  subtaskOrder: subtaskIndex,
                                  parallelSubtaskOrder: 0,
                                })
                              "
                            ></VueDatePicker>
                          </div>
                          <div class="grid-item">
                            <div class="d-flex justify-content-center">
                              <button
                                class="order-tables__edit-material-btn"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-subtask-material-modal"
                                @click="
                                  productToUpdate = {
                                    id: parallelSubtask.product?.id,
                                    dealId: dealId,
                                    taskId: item.id,
                                    subtaskId: parallelSubtask.id,
                                  }
                                "
                                :disabled="
                                  state.deal?.status_id != 1 ||
                                  state.deal?.is_disabled == true ||
                                  !parallelSubtask.product
                                "
                              >
                                <inline-svg
                                  :src="require('@/assets/images/edit-1.svg')"
                                  fill="#96AEC1"
                                  opacity="0.65"
                                />
                              </button>
                            </div>
                          </div>
                          <div class="grid-item">
                            <div class="d-flex justify-content-center">
                              <button
                                type="button"
                                class="order-tables__edit-material-btn"
                                :class="{
                                  error:
                                    v$.deal.tasks.$errors?.length &&
                                    v$.deal.tasks.$each.$response.$data[index]
                                      .subtasks.$each.$data[subtaskIndex]
                                      .subtasks.$each.$errors[
                                      parallelSubtaskIndex
                                    ]?.stock.length,
                                }"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-manufacture-modal"
                                title="Подробнее"
                                @click="
                                  productManufactureToUpdate = {
                                    id: parallelSubtask.id,
                                    deal_id: item.deal_id,
                                    isDisabled:
                                      state.deal?.status_id != 1 ||
                                      state.deal?.is_disabled == true,
                                    product: parallelSubtask.product,
                                    quantity: parallelSubtask.quantity,
                                    manufacture: parallelSubtask.manufacture,
                                    area: parallelSubtask.area,
                                    recivier_manufacture:
                                      parallelSubtask.receiver_manufacture,
                                    recivier_area:
                                      parallelSubtask.receiver_area,
                                    stock: parallelSubtask.stock,
                                    users: parallelSubtask.users,
                                    need_time: parallelSubtask.need_time,
                                    is_product: false,
                                    order: parallelSubtaskIndex,
                                    task_id: item.id,
                                    task_order: index,
                                    subtask_id: subtask.id,
                                    subtask_order: subtaskIndex,
                                    is_datepicker_disabled: false,
                                    measurement: parallelSubtask.measurement,
                                  }
                                "
                              >
                                <inline-svg
                                  width="20"
                                  height="20"
                                  :fill="
                                    v$.deal.tasks.$errors?.length &&
                                    v$.deal.tasks.$each.$response.$data[index]
                                      .subtasks.$each.$data[subtaskIndex]
                                      .subtasks.$each.$errors[
                                      parallelSubtaskIndex
                                    ]?.stock.length
                                      ? '#ff0558'
                                      : '#96aec1'
                                  "
                                  :src="require('@/assets/images/dots.svg')"
                                />
                              </button>
                            </div>
                          </div>
                          <div class="grid-item">
                            <button
                              type="button"
                              class="order-tables__action-btn"
                              title="Удалить подзадачу"
                              :disabled="
                                state.deal?.status_id != 1 ||
                                state.deal?.is_disabled == true
                              "
                              @click="
                                removeSubtask(
                                  item.deal_id,
                                  item.id,
                                  parallelSubtask.id,
                                  index
                                )
                              "
                            >
                              <inline-svg
                                width="24"
                                height="24"
                                stroke="#96aec1"
                                :src="require('@/assets/images/minus.svg')"
                              />
                            </button>
                          </div>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </template>
              </draggable>
            </template>
          </div>
        </div>

        <template v-if="materialsLength > 0">
          <h2 class="formation__table-title my-4">
            <span>Список материалов</span>
          </h2>

          <div
            class="order-tables__table-responsive table-responsive scrollbar-light"
            style="overflow-y: hidden"
          >
            <table class="table order-tables__table">
              <thead>
                <tr>
                  <th scope="col" style="width: 50px">№</th>
                  <th scope="col">Сырье</th>
                  <th scope="col">Изделие</th>
                  <th scope="col" style="min-width: 150px">Количество</th>
                </tr>
              </thead>
              <tbody :set="(counter = 0)">
                <template v-for="item in state.deal?.tasks" :key="item.id">
                  <tr v-for="material in item.materials" :key="material.title">
                    <td>{{ ++counter }}</td>
                    <td>
                      {{ material.title }}
                    </td>
                    <td>
                      {{ item.product?.title }}
                    </td>
                    <td>
                      {{ material.quantity }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </template>

        <div class="order-tables__item-bottom">
          <div class="d-flex">
            <button
              type="submit"
              class="btn btn-md btn-green w-100"
              style="max-width: 230px"
              :disabled="
                state.deal?.status_id != 1 ||
                state.deal?.is_disabled == true ||
                !isFormationActive
              "
            >
              Готово
            </button>

            <div class="d-flex align-items-center ms-4">
              <span> Общее время: </span>
              <span class="d-block ms-1">{{ totalTime }}</span>
            </div>
          </div>

          <span
            v-if="state.deal?.is_disabled == true"
            class="d-block text-success mt-1"
            style="font-size: 13px"
            >* Задачи были сформированны</span
          >
        </div>
      </form>
    </div>
  </div>

  <EditMaterialModal
    v-model:productData="productToUpdate"
    :measurementOptionsList="state.measurementOptionsList"
    :apiRequest="updateDealTaskMaterial"
    :refetch="getData"
  />
  <EditSubtaskMaterialModal
    v-model:productData="productToUpdate"
    :productOptionsList="state.productOptionsList"
    :stockOptionsList="state.stockOptionsList"
    :measurementOptionsList="state.measurementOptionsList"
    :apiRequest="addDealSubtaskProducts"
    :refetch="getData"
  />
  <EditManufactureModal
    id="add-manufacture-modal"
    title="Добавить подзадачу"
    v-model:entityToUpdate="productManufactureToAdd"
    :manufactureOptionsList="state.manufactureOptionsList"
    :productOptionsList="state.productOptionsList"
    :stockOptionsList="state.stockOptionsList"
    :measurementOptionsList="state.measurementOptionsList"
    :apiRequest="addDealSubtask"
    :refetch="getData"
    @updateTaskTime="updateTaskTime"
  />
  <EditManufactureModal
    id="edit-manufacture-modal"
    title="Подробная информация"
    v-model:entityToUpdate="productManufactureToUpdate"
    :manufactureOptionsList="state.manufactureOptionsList"
    :productOptionsList="state.productOptionsList"
    :stockOptionsList="state.stockOptionsList"
    :measurementOptionsList="state.measurementOptionsList"
    :apiRequest="putDealSubtask"
    :refetch="getData"
    @updateTaskTime="updateTaskTime"
  />
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import draggable from "vuedraggable";

import popperSelect from "@/mixins/popperSelect";
import useLoadingModal from "@/mixins/useLoadingModal";
import { successAlert, confirmAlert, errorAlert } from "@/helpers/alerts";

import {
  getManufactureList,
  getManufactureUserList as fetchManufactureUserList,
  getManufactureAreaList as fetchManufactureAreaList,
} from "@/api/manufacture";
import {
  getDealById,
  updateDealTasksById,
  addDealSubtask,
  putDealSubtask,
  addDealSubtaskProducts,
  deleteDealSubtask,
} from "@/api/deal";
import { updateDealTaskMaterial } from "@/api/material";
import { updateProductManufacture, getProducts } from "@/api/product";
import { getAllStock } from "@/api/stock";
import {
  updateTaskOrder,
  updateSubtaskTaskOrder,
  removeSubtaskTaskOrder,
} from "@/api/task";
import { getMeasurement } from "@/api/measurement";

import EditMaterialModal from "@/components/formation/EditMaterialModal.vue";
import EditSubtaskMaterialModal from "@/components/formation/EditSubtaskMaterialModal.vue";
import EditManufactureModal from "@/components/formation/EditManufactureModal.vue";

export default {
  components: {
    EditMaterialModal,
    EditSubtaskMaterialModal,
    EditManufactureModal,
    draggable,
  },
  mixins: [popperSelect],
  setup() {
    const route = useRoute();
    const store = useStore();
    const userPermissions = computed(() => store.getters.getUserPermissions);
    const isFormationActive = computed(
      () => userPermissions.value["31"]?.permissions["32"] ?? false
    );

    const router = useRouter();
    const isMinimized = true; // for select
    const dealId = route.params.id;
    const state = reactive({
      productOptionsList: [],
      stockOptionsList: [],
      manufactureOptionsList: [],
      areaOrUserOptionsList: [],
      measurementOptionsList: [],
      isAreaOrUserOptionsLoading: false,
      deal: {
        tasks: [],
      },
    });
    const { isDataLoading } = useLoadingModal();
    const productToUpdate = ref(null);
    const productManufactureToUpdate = ref(null);
    const productManufactureToAdd = ref(null);

    const materialsLength = computed(() => {
      let count = 0;

      state.deal.tasks?.forEach((item) => {
        item.materials?.forEach(() => {
          count++;
        });
      });

      return count;
    });

    const totalTime = computed(() => {
      const total = state.deal.tasks.reduce(
        (accum, task) => {
          // Calculate time for main tasks
          accum.totalDays += parseInt(task.need_time?.seconds) || 0; // Adding days; WE TAKE SECONDS AS DAYS!!!
          accum.totalHours += parseInt(task.need_time?.hours) || 0;
          accum.totalMinutes += parseInt(task.need_time?.minutes) || 0;

          // Calculate time for subtasks
          // if (task.subtasks && Array.isArray(task.subtasks)) {
          //   task.subtasks.forEach((subtask) => {
          //     accum.totalDays += parseInt(subtask.need_time?.seconds) || 0; // Adding days for subtasks; WE TAKE SECONDS AS DAYS!!!
          //     accum.totalHours += parseInt(subtask.need_time?.hours) || 0;
          //     accum.totalMinutes += parseInt(subtask.need_time?.minutes) || 0;
          //   });
          // }

          return accum;
        },
        { totalDays: 0, totalHours: 0, totalMinutes: 0 }
      );

      const extraHours = Math.floor(total.totalMinutes / 60);
      const remainingMinutes = total.totalMinutes % 60;

      total.totalHours += extraHours;
      total.totalMinutes = remainingMinutes;

      // Adding hours to days
      const extraDays = Math.floor(total.totalHours / 24);
      const remainingHours = total.totalHours % 24;

      total.totalDays += extraDays;
      total.totalHours = remainingHours;

      return `${String(total.totalDays).padStart(2, "0")}:${String(
        total.totalHours
      ).padStart(2, "0")}:${String(total.totalMinutes).padStart(2, "0")}`;
    });

    const rules = computed(() => ({
      deal: {
        tasks: {
          $each: helpers.forEach({
            id: { required },
            product: { required },
            quantity: {
              numeric: (value) => {
                if (value == null || value == "") return true;
                else if (value == 0) return false;
                else return /^\d+(\.\d+)?$/.test(value);
              },
            },
            manufacture: { required },
            area: {},
            users: { required },
            is_urgently: { required },
            need_time: { required },
            stock: { required },

            subtasks: {
              $each: helpers.forEach({
                id: { required },
                product: { required },
                quantity: {
                  numeric: (value) => {
                    if (value == null || value == "") return true;
                    else if (value == 0) return false;
                    else return /^\d+(\.\d+)?$/.test(value);
                  },
                },
                manufacture: { required },
                area: {},
                users: { required },
                is_urgently: { required },
                need_time: { required },
                stock: { required },

                subtasks: {
                  $each: helpers.forEach({
                    id: { required },
                    product: { required },
                    quantity: {
                      numeric: (value) => {
                        if (value == null || value == "") return true;
                        else if (value == 0) return false;
                        else return /^\d+(\.\d+)?$/.test(value);
                      },
                    },
                    manufacture: { required },
                    area: {},
                    users: { required },
                    is_urgently: { required },
                    need_time: { required },
                    stock: { required },
                  }),
                },
              }),
            },
          }),
        },
      },
    }));
    const v$ = useVuelidate(rules, state);

    onMounted(async () => {
      try {
        state.manufactureOptionsList = await getManufactureList();
      } catch {
        errorAlert("Ошибка сервера!");
      }
    });

    onMounted(async () => {
      try {
        state.productOptionsList = await getProducts();
      } catch {
        errorAlert("Ошибка сервера!");
      }
    });

    onMounted(async () => {
      try {
        state.stockOptionsList = await getAllStock();
      } catch {
        errorAlert("Ошибка сервера!");
      }
    });

    onMounted(async () => {
      try {
        state.measurementOptionsList = await getMeasurement();
      } catch {
        errorAlert("Ошибка сервера!");
      }
    });

    onMounted(async () => {
      getData();
    });

    async function getData() {
      try {
        isDataLoading.value = true;
        state.deal = await getDealById(dealId);
      } catch {
        errorAlert("Ошибка сервера!");
      } finally {
        isDataLoading.value = false;
      }
    }

    async function getManufactureAreaList({
      taskId,
      subtaskId,
      parallelSubtaskId,
      shopId,
    }) {
      if (!taskId || !shopId) return;
      let task = state.deal?.tasks?.find((item) => item.id == taskId);

      if (task && subtaskId) {
        task = task?.subtasks?.find((item) => item.id == subtaskId);

        if (task && subtaskId && parallelSubtaskId) {
          task = task?.subtasks?.find((item) => item.id == parallelSubtaskId);
        }
      }
      if (task == undefined) return;

      try {
        task.isManufactureAreaListLoading = true;
        const userList = await fetchManufactureAreaList(shopId);
        task.manufactureAreaList = userList;
      } catch {
        errorAlert("Ошибка сервера!");
      } finally {
        task.isManufactureAreaListLoading = false;
      }
    }

    async function getManufactureUserList({
      taskId,
      subtaskId,
      parallelSubtaskId,
      manufactureId,
      receiverManufactureId,
    }) {
      if (!taskId || !manufactureId) return;
      let task = state.deal?.tasks?.find((item) => item.id == taskId);

      if (task && subtaskId) {
        task = task?.subtasks?.find((item) => item.id == subtaskId);

        if (task && subtaskId && parallelSubtaskId) {
          task = task?.subtasks?.find((item) => item.id == parallelSubtaskId);
        }
      }
      if (task == undefined) return;

      try {
        task.isManufactureUserListLoading = true;
        const userList = await fetchManufactureUserList(
          manufactureId,
          receiverManufactureId
        );
        task.manufactureUserList = userList;
      } catch {
        errorAlert("Ошибка сервера!");
      } finally {
        task.isManufactureUserListLoading = false;
      }
    }

    async function onSubmitForm() {
      await this.v$.deal.tasks.$touch();
      if (this.v$.deal.tasks.$invalid) return;

      confirmAlert("Отправить в производство?").then(async (result) => {
        if (result.isConfirmed) {
          const data = state.deal.tasks.map((item) => ({
            id: item.id,
            stock_id: item.stock?.stock_id_c,
            user_id: item.users.map((user) => user?.id),
            manufacture_id: item.manufacture?.id,
            area_id: item.area?.id,
            is_urgently: item.is_urgently,
            need_time: `${String(item.need_time?.seconds).padStart(
              2,
              "0"
            )}:${String(item.need_time?.hours).padStart(2, "0")}:${String(
              item.need_time?.minutes
            ).padStart(2, "0")}`,

            subtasks: item.subtasks?.map((subitem) => ({
              id: subitem.id,
              user_id: subitem.users.map((user) => user?.id),
              is_urgently: subitem.is_urgently,
              need_time: `${String(subitem.need_time?.seconds).padStart(
                2,
                "0"
              )}:${String(subitem.need_time?.hours).padStart(2, "0")}:${String(
                subitem.need_time?.minutes
              ).padStart(2, "0")}`,

              subtasks: subitem.subtasks?.map((parallelsubitem) => ({
                id: parallelsubitem.id,
                user_id: parallelsubitem.users.map((user) => user?.id),
                is_urgently: parallelsubitem.is_urgently,
                need_time: `${String(
                  parallelsubitem.need_time?.seconds
                ).padStart(2, "0")}:${String(
                  parallelsubitem.need_time?.hours
                ).padStart(2, "0")}:${String(
                  parallelsubitem.need_time?.minutes
                ).padStart(2, "0")}`,
              })),
            })),
          }));

          try {
            await updateDealTasksById(dealId, data, totalTime.value);
            successAlert("Успешно отправлено").then(() => {
              if (window?.history?.state?.back == "/dashboard")
                router.push({ name: "dashboard" });
              else router.push({ name: "applications-page" });
            });
          } catch {
            errorAlert("Ошибка сервера!");
          }
        }
      });
    }

    const removeSubtask = async (deal_id, task_id, subtask_id, task_order) => {
      confirmAlert("Подтвердить действие").then(async (result) => {
        if (result.isConfirmed) {
          try {
            isDataLoading.value = true;
            await deleteDealSubtask(deal_id, task_id, subtask_id);
            await getData();
            await updateTaskTime({ taskId: task_id, taskOrder: task_order });
          } catch {
            isDataLoading.value = false;
          }
        }
      });
    };

    const updateData = async ({
      taskId,
      subtaskId,
      parallelSubtaskId,
      isNotLoading,
      order,
    }) => {
      let task = state.deal?.tasks?.find((item) => item.id == taskId);

      if (task && subtaskId) {
        task = task?.subtasks?.find((item) => item.id == subtaskId);

        if (task && subtaskId && parallelSubtaskId) {
          task = task?.subtasks?.find((item) => item.id == parallelSubtaskId);
        }
      }
      if (task == undefined) return;

      try {
        if (!isNotLoading) isDataLoading.value = true;
        await putDealSubtask({
          task_id: task.id,
          deal_id: task.deal_id,
          manufacture_id: task.manufacture?.id,
          area_id: task.area?.id,
          recivier_manufacture_id: task.recivier_manufacture?.id,
          recivier_area_id: task.recivier_area?.id,
          product_id: task.product?.id,
          quantity: task.quantity,
          stock_id: task.stock?.stock_id_c,
          is_urgently: false,
          need_time: `${String(task.need_time?.seconds).padStart(
            2,
            "0"
          )}:${String(task.need_time?.hours).padStart(2, "0")}:${String(
            task.need_time?.minutes
          ).padStart(2, "0")}`,
          user_id: task.users?.map((user) => user?.id),
          order: parallelSubtaskId ? 0 : order,
        });
      } finally {
        if (!isNotLoading) isDataLoading.value = false;
      }
    };

    const updateTaskTime = async ({
      taskId,
      subtaskId,
      parallelSubtaskId,
      taskOrder,
      subtaskOrder,
      parallelSubtaskOrder,
    }) => {
      let task = state.deal?.tasks?.find((item) => item.id == taskId);
      if (task == undefined) return;

      // Function to normalize time for a single task
      const normalizeTime = (timeObj) => {
        timeObj.totalHours += Math.floor(timeObj.totalMinutes / 60);
        timeObj.totalMinutes = timeObj.totalMinutes % 60;

        // Adding hours to days
        const extraDays = Math.floor(timeObj.totalHours / 24);
        const remainingHours = timeObj.totalHours % 24;

        timeObj.totalDays += extraDays;
        timeObj.totalHours = remainingHours;

        return timeObj;
      };

      // Recursive function to calculate total time for each subtask and update its need_time
      const calculateSubtaskTime = (subtask) => {
        let subtaskTime = { totalDays: 0, totalHours: 0, totalMinutes: 0 };

        // Calculate time for current subtask
        subtaskTime.totalDays += parseInt(subtask.need_time?.seconds) || 0;
        subtaskTime.totalHours += parseInt(subtask.need_time?.hours) || 0;
        subtaskTime.totalMinutes += parseInt(subtask.need_time?.minutes) || 0;

        // If the subtask has its own subtasks, recurse
        if (subtask.subtasks && subtask.subtasks.length > 0) {
          const nestedTotal = subtask.subtasks.reduce(
            (accum, nestedSubtask) => {
              // const nestedTime = calculateSubtaskTime(nestedSubtask);
              let nestedTime = { totalDays: 0, totalHours: 0, totalMinutes: 0 };

              // Calculate time for current subtask
              nestedTime.totalDays +=
                parseInt(nestedSubtask.need_time?.seconds) || 0;
              nestedTime.totalHours +=
                parseInt(nestedSubtask.need_time?.hours) || 0;
              nestedTime.totalMinutes +=
                parseInt(nestedSubtask.need_time?.minutes) || 0;

              accum.totalDays += nestedTime.totalDays;
              accum.totalHours += nestedTime.totalHours;
              accum.totalMinutes += nestedTime.totalMinutes;
              return accum;
            },
            { totalDays: 0, totalHours: 0, totalMinutes: 0 }
          );

          subtaskTime.totalDays = nestedTotal.totalDays;
          subtaskTime.totalHours = nestedTotal.totalHours;
          subtaskTime.totalMinutes = nestedTotal.totalMinutes;
        }

        // Normalize the time for the current subtask after adding nested subtasks' time
        subtaskTime = normalizeTime(subtaskTime);

        // Update subtask's need_time with the calculated time
        subtask.need_time = {
          seconds: subtaskTime.totalDays,
          hours: subtaskTime.totalHours,
          minutes: subtaskTime.totalMinutes,
        };

        return subtaskTime;
      };

      // Calculate time for each subtask
      task.subtasks.forEach((subtask) => {
        calculateSubtaskTime(subtask);
      });

      // Calculate total time for the main task based on its direct subtasks
      const total = task.subtasks.reduce(
        (accum, subtask) => {
          // Calculate time for main tasks

          accum.totalDays += parseInt(subtask.need_time?.seconds) || 0; // Adding days; WE TAKE SECONDS AS DAYS!!!
          accum.totalHours += parseInt(subtask.need_time?.hours) || 0;
          accum.totalMinutes += parseInt(subtask.need_time?.minutes) || 0;

          return accum;
        },
        { totalDays: 0, totalHours: 0, totalMinutes: 0 }
      );

      const normalizedTotal = normalizeTime(total);

      task.need_time = {
        seconds: normalizedTotal.totalDays,
        hours: normalizedTotal.totalHours,
        minutes: normalizedTotal.totalMinutes,
      };

      try {
        isDataLoading.value = true;
        if (
          parallelSubtaskId != undefined &&
          parallelSubtaskOrder != undefined &&
          subtaskId != undefined
        )
          await updateData({
            taskId,
            subtaskId,
            parallelSubtaskId,
            isNotLoading: true,
            order: 0,
          });
        if (subtaskId != undefined && subtaskOrder != undefined)
          await updateData({
            taskId,
            subtaskId,
            isNotLoading: true,
            order: subtaskOrder,
          });

        await updateData({
          taskId,
          isNotLoading: true,
          order: taskOrder,
        });

        await getData();
      } finally {
        isDataLoading.value = false;
      }
    };

    // Function for "unfolding" triple nesting
    function flattenSubtasks(subtasks) {
      if (!subtasks) return;
      for (let i = 0; i < subtasks.length; i++) {
        const subtask = subtasks[i];
        if (subtask.subtasks && subtask.subtasks.length > 0) {
          for (let j = 0; j < subtask.subtasks.length; j++) {
            const subSubtask = subtask.subtasks[j];
            if (subSubtask.subtasks && subSubtask.subtasks.length > 0) {
              subtask.subtasks.push(...subSubtask.subtasks);
              subSubtask.subtasks = [];
            }
          }
          flattenSubtasks(subtask.subtasks);
        }
      }
    }

    watch(
      () => state.deal.tasks,
      (newVal) => {
        if (newVal) {
          for (const task of newVal) {
            if (task.subtasks) {
              flattenSubtasks(task.subtasks);
            }
          }
        }
      },
      { deep: true }
    );

    const onDragEnd = async (event, taskId, notLoading) => {
      if (!event?.moved && event != null) return;

      try {
        isDataLoading.value = true;
        let task = state.deal?.tasks?.find((item) => item.id == taskId);
        if (task == undefined) return;

        const data = task.subtasks?.map((item, index) => ({
          id: item.id,
          order: index,
        }));

        await updateTaskOrder(taskId, data);
      } finally {
        if (!notLoading) isDataLoading.value = false;
      }
    };

    const onDragEndSubtask = async (event, dealId, taskId, subtaskId) => {
      if (event.removed) {
        const elementId = event?.removed?.element?.id;

        let taskOrder = state.deal?.tasks?.findIndex(
          (item) => item.id == taskId
        );
        let task = state.deal?.tasks?.find((item) => item.id == taskId);
        if (task == undefined) return;
        let subtaskOrder = task?.subtasks?.findIndex(
          (item) => item.id == subtaskId
        );
        let subtask = task?.subtasks?.find((item) => item.id == subtaskId);
        if (subtask == undefined) return;

        try {
          isDataLoading.value = true;
          if (task?.subtasks?.findIndex((item) => item.id == elementId) != -1)
            await removeSubtaskTaskOrder(dealId, taskId, subtaskId, elementId);
          await onDragEnd(null, taskId, true);
          await updateTaskTime({
            taskId,
            subtaskId,
            parallelSubtaskId: elementId,
            taskOrder,
            subtaskOrder,
            parallelSubtaskOrder: 0,
          });
        } finally {
          isDataLoading.value = false;
        }
      } else {
        try {
          isDataLoading.value = true;
          let taskOrder = state.deal?.tasks?.findIndex(
            (item) => item.id == taskId
          );
          let task = state.deal?.tasks?.find((item) => item.id == taskId);
          if (task == undefined) return;
          let subtaskOrder = task?.subtasks?.findIndex(
            (item) => item.id == subtaskId
          );
          let subtask = task?.subtasks?.find((item) => item.id == subtaskId);
          if (subtask == undefined) return;

          const data = subtask.subtasks?.map((item) => ({
            id: item.id,
            order: 0,
          }));

          await updateSubtaskTaskOrder(dealId, taskId, subtaskId, data);
          await onDragEnd(null, taskId, true);
          await updateTaskTime({
            taskId,
            subtaskId,
            parallelSubtaskId: event?.added?.element?.id,
            taskOrder,
            subtaskOrder,
            parallelSubtaskOrder: 0,
          });
        } finally {
          isDataLoading.value = false;
        }
      }
    };

    return {
      state,
      isFormationActive,
      productToUpdate,
      productManufactureToUpdate,
      productManufactureToAdd,
      dealId,
      materialsLength,
      v$,
      isMinimized,
      totalTime,
      getData,
      getManufactureUserList,
      getManufactureAreaList,
      onSubmitForm,
      updateDealTaskMaterial,
      updateProductManufacture,
      addDealSubtask,
      addDealSubtaskProducts,
      putDealSubtask,
      removeSubtask,
      updateData,
      updateTaskTime,
      onDragEnd,
      onDragEndSubtask,
    };
  },
};
</script>

<style lang="scss" scoped>
.border-blue {
  border-color: var(--main-blue-border);
}
</style>

<style lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.grid-header {
  background-color: var(--main-table-th);
  color: var(--main-blue);
  padding: 0.5rem;
}

.grid-item-wrapper {
  display: contents;
}

.grid-subtask-wrapper {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column: span 10;
}

.grid-item {
  padding: 0.5rem;
  font-size: 14px;
}

.maintask {
  display: contents;

  // & .grid-item {
  //   padding-bottom: 20px;
  // }
}

.subtask {
  display: grid;
  grid-template-columns: 58px 176px 106px 176px 176px 176px 96px 58px 58px 58px;
  grid-column: span 10;
  margin: 0 20px;
  // display: contents;
  // pointer-events: all;

  & .grid-item {
    cursor: grab;
    background-color: #eee;
  }

  &.not-draggable .grid-item {
    cursor: default;
  }

  &.ghost-ticket {
    cursor: grabbing !important;
    opacity: 0.5;
  }
}

.grid-subtask-wrapper .subtask {
  margin: 0 0 0 10px;
  grid-template-columns: 48px 176px 106px 176px 176px 176px 96px 58px 58px 58px;
}

.dots-vertical-wrapper {
  position: relative;
  padding: 0.5rem;
  padding-left: 0.8rem;
  height: 100%;
}

.subtask.not-draggable .dots-vertical-wrapper {
  padding-left: 0.5rem;
}

.dots-vertical {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #d9d9d9;
  display: flex;
  align-items: flex-start;
  padding-top: 0.5rem;

  svg {
    transform: scale(1.45);
  }
}

.subtask.not-draggable .dots-vertical {
  display: none;
}

.dp__time_col_sec:nth-child(1) {
  order: 3;
}
.dp__time_col_sec:nth-child(2) {
  order: 2;
}
.dp__time_col_sec:nth-child(3) {
  order: 5;
}
.dp__time_col_sec:nth-child(4) {
  order: 4;
}
.dp__time_col_sec:nth-child(5) {
  order: 1;
}

.dp__action_buttons {
  width: 100%;
}

.dp__selection_preview {
  display: none;
}

.dp__select {
  visibility: hidden;
  width: 80px;

  &::after {
    content: "Выбрать";
    visibility: visible;
    display: block;
    position: absolute;
  }
}

.dp__cancel {
  visibility: hidden;
  width: 70px;

  &::after {
    content: "Отмена";
    visibility: visible;
    display: block;
    position: absolute;
  }
}
</style>