import axios from '@/api/axios';

const getMaterials = async (product_id) => {
  const { data: response } = await axios.get('/materials', {
    params: {
      product_id,
    },
  });
  return response;
};

const updateDealTaskMaterial = async ({ dealId, taskId, data }) => {
  const { data: response } = await axios.put(`/deals/${dealId}/tasks/${taskId}/materials`, {
    materials: data.map((item) => ({
      ...item,
      measurement_id: item.measurement?.measurement_id_c,
    })),
  });
  return response;
};

const updateTaskMaterial = async ({ taskId, data }) => {
  const { data: response } = await axios.put(`/tasks/${taskId}/update-material`, {
    materials: data,
  });
  return response;
};

export { getMaterials, updateDealTaskMaterial, updateTaskMaterial };
