<template>
  <section class="formation">
    <div class="d-flex align-items-center justify-content-start mb-4">
      <router-link class="main-tab__title" :to="{ name: 'applications-page' }">
        Заявки
      </router-link>
      <h2 class="main-tab__title active">Формирование</h2>
    </div>

    <OrderTables />
  </section>
</template>

<script>
import OrderTables from "@/components/formation/OrderTables.vue";

export default {
  name: "plan-page",
  components: { OrderTables },
};
</script>